import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1079.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1079.000000) scale(0.100000,-0.100000)">
					<path d="M5035 7694 c-69 -10 -152 -26 -185 -36 -61 -19 -174 -60 -200 -73
-14 -7 -38 -19 -55 -26 -83 -37 -241 -156 -331 -249 -66 -68 -129 -150 -117
-150 5 0 33 23 63 51 123 113 292 208 454 255 168 49 238 57 401 51 317 -14
567 -126 796 -358 100 -102 133 -147 217 -301 60 -108 97 -246 123 -451 18
-149 21 -145 -214 -228 -174 -61 -332 -107 -482 -139 -27 -6 -86 -19 -130 -29
-44 -10 -128 -25 -187 -32 -119 -14 -128 -11 -152 45 -17 41 -81 102 -132 126
-55 27 -163 27 -219 0 -59 -28 -120 -92 -140 -144 -10 -25 -25 -48 -35 -51
-23 -7 -223 12 -328 31 -108 20 -297 88 -358 128 -121 80 -186 160 -200 249
-9 50 -20 47 -27 -8 -8 -54 6 -132 32 -183 30 -58 137 -153 216 -191 105 -50
249 -100 334 -115 153 -28 207 -36 261 -37 71 -2 85 -10 121 -69 73 -119 237
-168 356 -106 43 22 104 85 120 125 18 44 28 51 72 51 18 0 61 4 95 10 33 5
99 14 146 20 79 11 188 31 270 51 19 4 55 13 80 18 25 6 81 20 125 31 44 12
98 26 120 31 22 5 75 21 118 36 102 35 108 29 78 -74 -13 -43 -39 -107 -59
-143 -20 -36 -46 -82 -57 -102 -35 -65 -144 -195 -218 -261 -181 -161 -366
-246 -647 -297 l-95 -17 73 -7 c76 -8 235 0 287 14 17 5 53 13 80 20 89 20
278 99 350 146 39 25 81 52 95 59 14 7 75 62 136 122 139 136 248 295 299 435
25 67 62 179 74 225 11 38 17 44 84 77 137 67 235 126 332 200 211 160 335
336 335 475 0 193 -167 338 -505 440 -64 19 -275 54 -282 47 -2 -2 41 -18 94
-36 129 -43 217 -90 286 -151 68 -60 91 -96 110 -172 20 -75 8 -157 -33 -244
-28 -57 -112 -161 -169 -208 -13 -11 -44 -37 -69 -58 -53 -46 -139 -91 -147
-77 -3 5 -10 49 -16 97 -13 118 -25 172 -61 273 -51 144 -76 196 -134 287
-190 297 -458 488 -808 574 -60 15 -125 21 -246 24 -91 2 -178 1 -195 -1z"/>
					<path d="M3152 4977 c-18 -6 -47 -23 -64 -38 l-30 -29 21 -29 c19 -26 21 -43
21 -178 0 -137 -2 -151 -20 -168 -11 -10 -17 -22 -14 -26 3 -5 90 -9 195 -9
200 0 206 2 175 46 -8 11 -19 39 -25 62 -10 37 -31 59 -31 32 0 -5 -14 -25
-31 -45 -26 -29 -38 -35 -70 -35 -62 0 -95 45 -87 121 4 43 29 49 63 15 32
-32 50 -34 41 -3 -4 12 -12 46 -18 75 -13 56 -27 66 -43 30 -6 -13 -17 -23
-25 -23 -24 0 -18 109 7 143 22 30 75 41 99 21 19 -16 18 -56 -3 -67 -31 -18
-8 -42 40 -42 56 0 87 30 87 83 0 34 -2 37 -29 37 -16 0 -50 9 -76 20 -51 22
-128 25 -183 7z"/>
					<path d="M3755 4971 c-50 -22 -69 -57 -68 -123 1 -65 24 -87 122 -118 38 -12
80 -33 92 -46 32 -34 28 -82 -11 -116 -42 -39 -92 -38 -131 1 -16 16 -29 34
-29 39 0 18 30 34 55 30 53 -11 37 43 -18 61 -84 28 -149 -61 -100 -135 27
-42 83 -64 164 -64 86 0 131 19 165 69 32 47 32 106 1 152 -17 26 -43 43 -106
70 -87 37 -114 60 -104 87 16 42 61 42 101 1 l30 -31 33 37 c37 40 53 64 47
70 -2 2 -17 6 -33 9 -17 3 -46 10 -65 16 -51 15 -100 12 -145 -9z"/>
					<path d="M4612 4969 c-67 -26 -80 -49 -57 -99 14 -31 16 -62 13 -180 -3 -124
-5 -143 -22 -155 -39 -29 -7 -35 185 -35 124 0 189 4 189 10 0 6 -9 28 -20 50
-11 22 -20 48 -20 59 0 24 -22 40 -29 20 -2 -8 -19 -29 -37 -46 -42 -43 -92
-45 -129 -5 -22 23 -25 35 -23 77 4 60 21 68 67 29 18 -14 34 -24 38 -21 3 3
-2 27 -11 53 -9 26 -16 58 -16 71 0 30 -14 29 -34 -2 -28 -43 -46 -32 -46 29
0 73 31 120 82 124 30 3 40 -1 54 -23 16 -25 16 -27 -5 -48 -27 -29 -12 -47
39 -47 51 0 80 30 80 80 0 37 -2 40 -27 40 -16 0 -52 9 -81 20 -65 25 -122 25
-190 -1z"/>
					<path d="M5085 4974 c-43 -16 -105 -70 -105 -91 0 -7 4 -13 9 -13 23 0 33 -64
29 -191 -3 -109 -7 -133 -21 -144 -36 -26 -19 -35 68 -35 91 0 104 9 65 43
-18 15 -20 29 -19 162 0 137 2 147 23 172 17 20 33 27 59 27 96 0 130 -136 46
-183 -24 -13 -29 -13 -53 2 -30 20 -32 35 -11 63 14 19 15 18 34 -6 17 -22 21
-23 26 -8 13 34 1 53 -37 56 -43 4 -68 -23 -68 -73 0 -49 44 -85 104 -85 61 0
102 28 128 87 17 39 18 51 8 94 -27 113 -158 169 -285 123z"/>
					<path d="M5974 4976 c-77 -28 -107 -74 -68 -107 13 -11 15 -36 12 -165 -3
-132 -5 -155 -22 -173 -21 -23 -15 -28 39 -33 28 -3 30 -6 27 -37 -4 -48 -18
-61 -66 -61 -58 0 -103 -31 -128 -86 -25 -57 -18 -105 20 -134 24 -19 34 -21
102 -14 41 3 76 7 77 8 1 0 7 39 13 86 11 82 16 106 40 198 l11 42 119 0 c98
0 120 3 120 14 0 8 -4 17 -9 20 -5 4 -17 31 -26 61 -19 58 -30 68 -40 35 -10
-33 -63 -70 -100 -70 -55 0 -77 27 -79 95 -1 71 14 80 63 39 18 -14 34 -25 36
-22 8 8 -30 148 -40 148 -6 0 -15 -10 -20 -22 -6 -13 -18 -24 -29 -26 -17 -4
-18 2 -14 62 3 51 9 71 28 91 32 34 83 34 106 0 16 -25 16 -27 -7 -51 -20 -21
-21 -27 -9 -34 8 -5 32 -10 52 -10 50 0 80 32 76 80 -2 29 -7 36 -28 38 -14 2
-49 12 -78 23 -58 21 -130 23 -178 5z m-48 -649 c9 -38 -10 -90 -38 -107 -65
-36 -108 50 -53 105 33 34 82 34 91 2z"/>
					<path d="M6435 4975 c-46 -18 -95 -62 -95 -85 0 -9 7 -25 15 -36 11 -14 14
-50 15 -149 0 -112 -3 -134 -19 -159 -11 -17 -18 -33 -15 -38 3 -4 39 -8 80
-8 80 0 87 6 58 48 -13 19 -15 48 -12 168 3 146 3 146 31 170 57 49 137 16
137 -56 0 -44 -26 -70 -72 -70 -31 0 -53 30 -43 60 9 27 45 26 45 -1 0 -12 5
-19 13 -17 18 7 20 34 2 51 -12 13 -21 14 -45 5 -67 -23 -64 -78 10 -188 41
-60 50 -81 48 -109 -2 -28 -8 -36 -27 -41 -22 -5 -26 -14 -37 -85 -7 -44 -18
-109 -25 -145 -13 -73 -10 -122 8 -127 17 -6 32 27 42 94 41 262 32 243 120
243 38 0 72 4 75 9 3 4 -4 17 -15 27 -27 24 -60 88 -63 123 -3 21 -10 28 -40
35 -20 5 -36 12 -36 16 0 3 17 12 37 19 59 22 83 52 83 107 0 58 -30 106 -80
131 -47 23 -145 27 -195 8z"/>
					<path d="M6885 4974 c-53 -19 -102 -68 -108 -108 -9 -61 12 -75 32 -21 40 112
226 114 282 3 24 -46 30 -159 10 -195 -27 -51 -95 -82 -133 -62 -18 9 -19 21
-16 137 2 70 7 135 12 145 7 14 3 17 -27 17 -59 0 -124 -72 -87 -95 6 -4 10
-56 10 -122 0 -106 -2 -119 -21 -140 -19 -20 -20 -23 -6 -29 9 -3 65 -4 124
-2 133 4 173 21 218 93 25 41 31 61 33 125 3 63 0 84 -18 123 -55 117 -188
174 -305 131z"/>
					<path d="M7895 4970 c-74 -35 -93 -63 -60 -90 12 -10 15 -42 15 -170 0 -128
-3 -160 -15 -170 -8 -7 -15 -19 -15 -26 0 -11 35 -14 190 -14 105 0 190 3 190
6 0 15 -54 144 -61 144 -3 0 -12 -13 -19 -30 -25 -61 -97 -79 -146 -36 -24 20
-28 31 -29 77 0 44 3 54 18 57 11 2 32 -8 48 -24 34 -33 47 -26 32 19 -6 17
-14 49 -17 69 -9 49 -21 55 -36 17 -8 -19 -19 -29 -29 -27 -13 3 -15 15 -13
63 3 76 25 109 74 113 59 5 88 -41 48 -75 -27 -23 -8 -43 39 -43 45 1 69 18
81 59 12 43 3 61 -29 61 -14 0 -50 9 -79 20 -66 25 -135 25 -187 0z"/>
					<path d="M2263 4972 c-12 -2 -26 -9 -31 -15 -15 -20 -45 -107 -39 -113 3 -4
26 3 50 15 46 22 82 27 92 11 3 -5 -5 -16 -19 -25 -33 -22 -38 -39 -19 -76 21
-43 13 -218 -11 -231 -9 -5 -16 -16 -16 -23 0 -12 17 -15 79 -15 44 0 82 4 85
9 3 4 -3 16 -14 26 -18 16 -20 31 -20 165 0 160 -1 158 60 179 38 13 100 67
100 88 0 17 -30 16 -54 -2 -22 -17 -69 -19 -112 -6 -36 12 -102 18 -131 13z"/>
					<path d="M7478 4972 c-14 -2 -31 -9 -36 -15 -15 -21 -44 -108 -38 -115 4 -3
21 1 39 11 75 38 128 32 83 -10 -26 -24 -34 -57 -16 -68 6 -3 10 -50 10 -107
0 -83 -3 -104 -20 -128 -11 -15 -17 -30 -13 -34 8 -8 148 -8 156 0 3 4 -3 18
-13 32 -18 22 -20 41 -20 159 0 74 4 142 10 152 5 10 30 24 55 31 31 9 54 24
71 45 40 54 30 69 -28 39 -22 -12 -43 -14 -72 -9 -85 14 -142 20 -168 17z"/>
					<path d="M2613 4957 c-4 -6 -1 -13 5 -15 9 -3 12 -59 12 -202 0 -163 -3 -200
-15 -210 -8 -7 -12 -16 -9 -21 6 -10 147 -12 157 -2 4 3 -1 15 -10 26 -25 26
-32 157 -10 176 12 9 21 10 36 2 30 -16 41 -13 51 14 5 14 16 25 25 25 22 0
22 -192 0 -210 -8 -7 -15 -19 -15 -26 0 -11 19 -14 80 -14 61 0 80 3 80 14 0
7 -7 19 -15 26 -22 18 -22 382 0 400 25 21 8 28 -67 25 -40 -1 -74 -3 -76 -4
-2 0 4 -8 12 -17 11 -11 16 -35 16 -79 0 -62 -1 -65 -30 -80 -17 -9 -47 -17
-68 -18 l-37 -2 -3 66 c-2 36 2 79 8 95 6 16 11 31 11 34 -2 10 -132 7 -138
-3z"/>
					<path d="M4085 4962 c-3 -3 2 -13 10 -22 23 -25 24 -374 1 -399 -31 -34 -20
-41 63 -41 79 0 107 10 75 28 -30 17 -44 144 -20 177 13 18 16 18 41 5 26 -14
28 -13 47 13 38 52 48 37 48 -71 0 -86 -3 -101 -20 -117 -11 -10 -17 -22 -14
-26 3 -5 41 -9 85 -9 81 0 97 9 62 36 -16 11 -18 33 -18 203 0 139 3 193 13
203 6 6 12 15 12 19 0 5 -134 5 -148 0 -2 0 4 -11 12 -23 10 -14 16 -44 16
-81 0 -58 0 -58 -37 -74 -55 -23 -106 -23 -111 1 -7 35 10 146 25 161 8 8 13
16 11 16 -11 4 -149 5 -153 1z"/>
					<path d="M5430 4962 c0 -4 7 -16 15 -26 12 -16 15 -57 15 -201 0 -168 -1 -183
-20 -200 -11 -10 -17 -22 -14 -26 7 -12 161 -11 169 0 3 6 -4 15 -15 21 -18 9
-20 21 -20 95 0 91 8 105 47 84 22 -12 27 -11 39 5 7 10 15 24 17 29 2 6 11 8
20 4 32 -12 19 -197 -15 -218 -26 -16 8 -29 77 -29 79 0 101 12 66 36 -20 14
-21 23 -21 198 0 145 3 186 15 202 8 10 15 22 15 25 0 3 -34 6 -75 6 -65 0
-75 -3 -69 -16 15 -35 27 -108 21 -132 -5 -19 -20 -30 -66 -46 -32 -12 -62
-19 -65 -16 -3 4 -6 44 -6 89 0 62 4 87 16 98 8 9 14 17 12 17 -12 6 -158 6
-158 1z"/>
					<path d="M8270 4961 c0 -4 7 -16 15 -27 11 -15 14 -56 15 -195 0 -164 -2 -178
-21 -203 -16 -20 -18 -27 -7 -31 8 -3 88 -4 178 -3 141 3 164 5 161 18 -3 8
-7 44 -11 80 -12 121 -60 170 -60 62 0 -48 -4 -62 -23 -80 -47 -44 -109 -18
-122 51 -4 19 -5 91 -3 160 2 106 6 128 22 146 11 12 17 22 15 22 -16 6 -159
5 -159 0z"/>
					<path d="M2977 4463 c-4 -3 -7 -17 -7 -30 0 -18 -7 -25 -31 -30 -40 -8 -48
-31 -20 -53 21 -15 22 -22 16 -81 -6 -55 -4 -68 12 -88 16 -20 25 -23 55 -17
41 8 63 29 54 52 -4 12 -14 14 -36 9 l-30 -7 0 49 c0 60 6 69 50 80 43 11 49
44 10 53 -17 4 -26 14 -29 30 -4 32 -28 50 -44 33z"/>
					<path d="M5364 4441 c-5 -24 -13 -33 -38 -39 -38 -10 -49 -46 -17 -55 19 -5
20 -10 15 -65 -9 -82 -1 -109 34 -117 43 -9 84 10 80 38 -2 17 -9 21 -27 19
-33 -3 -39 10 -31 64 8 49 21 64 58 64 17 0 22 6 22 25 0 18 -5 25 -20 25 -11
0 -23 8 -26 18 -17 55 -42 66 -50 23z"/>
					<path d="M2723 4385 c-35 -15 -51 -52 -36 -80 6 -9 27 -27 49 -39 57 -32 52
-51 -14 -52 -55 -1 -80 -14 -67 -34 15 -23 58 -31 108 -19 88 21 102 88 27
131 -22 13 -42 29 -44 35 -8 21 31 36 64 24 33 -11 55 5 44 33 -8 20 -85 21
-131 1z"/>
					<path d="M3197 4386 c-21 -8 -48 -22 -59 -33 -39 -34 -59 -112 -42 -158 11
-28 62 -44 102 -33 24 7 43 6 60 -2 34 -15 42 -1 42 81 0 37 3 81 6 98 6 28 4
32 -29 46 -20 8 -38 15 -39 15 -2 -1 -20 -7 -41 -14z m44 -96 c-1 -30 -8 -61
-13 -67 -17 -20 -66 -16 -78 7 -21 39 38 126 80 118 12 -3 14 -15 11 -58z"/>
					<path d="M3890 4383 c-49 -34 -90 -100 -90 -145 0 -60 78 -101 141 -74 44 19
61 37 49 56 -7 11 -14 12 -35 2 -81 -37 -122 15 -72 89 21 32 27 34 77 34 62
0 75 17 31 39 -39 21 -71 20 -101 -1z"/>
					<path d="M4325 4383 c-22 -56 -37 -214 -20 -219 20 -7 35 13 44 60 9 50 58
121 82 121 11 0 13 -18 11 -85 -3 -88 6 -117 31 -107 26 10 48 188 27 226 -10
20 -17 22 -53 17 -56 -8 -58 -8 -88 -1 -20 5 -28 2 -34 -12z"/>
					<path d="M4566 4389 c-3 -8 -12 -48 -20 -89 -9 -41 -18 -84 -22 -96 -7 -24 17
-47 40 -38 10 4 16 18 16 35 0 34 28 93 60 127 37 39 45 24 41 -76 -3 -79 -1
-93 14 -98 29 -11 39 14 46 109 9 116 -3 142 -59 133 -49 -8 -52 -8 -84 0 -19
4 -29 2 -32 -7z"/>
					<path d="M4853 4381 c-51 -23 -76 -67 -77 -133 -1 -67 17 -83 99 -83 53 0 68
4 87 22 17 18 20 25 10 35 -9 9 -17 9 -35 -1 -28 -15 -89 -11 -95 7 -2 7 19
23 55 38 71 32 93 50 93 80 0 44 -75 63 -137 35z m67 -41 c0 -18 -63 -51 -75
-39 -7 7 -3 16 14 30 25 20 61 26 61 9z"/>
					<path d="M5095 4369 c-45 -40 -65 -78 -65 -124 0 -51 15 -73 59 -86 33 -10 45
-8 84 9 28 12 47 27 47 37 0 20 -28 31 -43 16 -18 -18 -72 -13 -92 9 -17 18
-16 21 9 64 28 48 47 57 106 53 47 -3 54 26 9 42 -51 18 -76 13 -114 -20z"/>
					<path d="M5586 4390 c-43 -13 -73 -47 -91 -101 -15 -44 -15 -50 0 -79 26 -50
104 -66 165 -35 38 19 44 62 8 50 -13 -4 -43 -8 -68 -9 -66 -1 -58 22 19 54
66 29 91 51 91 82 0 31 -72 53 -124 38z m49 -50 c3 -5 -8 -16 -24 -25 -37 -19
-51 -19 -51 0 0 26 61 47 75 25z"/>
					<path d="M6317 4385 c-76 -26 -117 -96 -102 -172 8 -43 51 -65 99 -52 17 4 46
7 65 6 l34 -2 2 80 c1 44 5 90 10 103 6 19 2 24 -29 37 -19 8 -37 15 -38 15
-2 -1 -20 -7 -41 -15z m45 -95 c-2 -30 -9 -61 -15 -68 -14 -17 -67 -11 -80 10
-14 22 19 97 47 109 42 17 51 7 48 -51z"/>
					<path d="M7018 4390 c-72 -21 -118 -82 -118 -154 0 -60 28 -76 127 -74 l84 2
-2 51 c-1 27 2 75 6 106 l7 56 -32 12 c-37 12 -35 12 -72 1z m39 -101 c-6 -68
-16 -81 -62 -77 -23 2 -31 9 -33 26 -7 51 37 112 82 112 17 0 18 -6 13 -61z"/>
					<path d="M7490 4387 c-31 -16 -54 -61 -42 -83 5 -9 26 -26 48 -38 21 -12 40
-30 42 -40 3 -15 -3 -17 -45 -16 -26 1 -56 1 -65 1 -31 -2 -19 -32 19 -47 30
-13 44 -14 79 -4 90 25 100 85 22 135 -26 17 -47 33 -47 37 -2 17 41 29 68 20
34 -12 54 -2 49 24 -2 15 -13 20 -53 22 -28 1 -61 -4 -75 -11z"/>
					<path d="M3372 4378 c-8 -10 -4 -29 14 -73 50 -117 50 -116 15 -170 -32 -50
-41 -95 -18 -95 20 0 32 13 56 60 13 25 47 81 76 125 72 110 89 145 77 157
-17 17 -37 3 -76 -57 -21 -31 -41 -54 -44 -50 -3 3 -18 29 -32 58 -26 53 -48
68 -68 45z"/>
					<path d="M4113 4368 c-41 -29 -52 -49 -60 -103 -15 -109 98 -151 171 -62 31
37 49 96 40 132 -14 56 -94 73 -151 33z m95 -66 c5 -46 -31 -94 -66 -90 -41 5
-51 46 -22 92 19 32 27 37 54 34 27 -3 31 -7 34 -36z"/>
					<path d="M6622 4373 c-4 -10 -7 -54 -6 -98 1 -135 36 -158 84 -56 13 29 28 50
34 48 6 -2 14 -25 18 -52 10 -74 38 -85 73 -31 29 45 84 178 78 192 -11 30
-35 12 -60 -46 -30 -68 -43 -76 -43 -26 0 51 -17 88 -38 84 -9 -2 -30 -27 -45
-56 -16 -28 -32 -52 -38 -52 -5 0 -9 18 -9 39 0 22 -3 46 -6 55 -8 21 -33 20
-42 -1z"/>
					<path d="M7191 4376 c-9 -11 -7 -26 10 -67 43 -109 43 -114 9 -181 -17 -33
-29 -66 -26 -74 10 -26 36 -14 57 27 11 23 35 63 53 88 47 68 116 191 116 207
0 15 -18 18 -36 6 -6 -4 -26 -31 -44 -60 -18 -29 -37 -51 -43 -49 -5 1 -17 21
-26 44 -17 42 -38 73 -51 73 -4 0 -13 -6 -19 -14z"/>
					<path d="M7702 4188 c-20 -20 -14 -38 14 -38 20 0 25 4 22 22 -4 26 -19 33
-36 16z"/>
					<path d="M7902 4188 c-22 -22 -14 -40 16 -36 19 2 27 9 27 23 0 23 -25 31 -43
13z"/>
					<path d="M8100 4185 c-9 -11 -10 -19 -3 -26 17 -17 48 -6 48 16 0 25 -28 31
-45 10z"/>
					<path d="M0 40 l0 -40 5400 0 5400 0 0 40 0 40 -5400 0 -5400 0 0 -40z" />
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
